<script setup>
import { computed } from "vue"
import { router } from "@inertiajs/vue3"

const props = defineProps({ status: Number })

const title = computed(() => {
    return {
        503: "503: Service Unavailable",
        500: "500: Server Error",
        404: "404: Page Not Found",
        403: "403: Forbidden",
    }[props.status]
})

const description = computed(() => {
    return {
        503: "Sorry, we are doing some maintenance. Please check back soon.",
        500: "Whoops, something went wrong on our servers.",
        404: "Sorry, the page you are looking for could not be found.",
        403: "Sorry, you are forbidden from accessing this page.",
    }[props.status]
})

import { ArrowLeft, RefreshCcw, Home } from "lucide-vue-next"

const icons = {
    ArrowLeft: ArrowLeft,
    RefreshCcw: RefreshCcw,
    Home: Home,
}

const goBack = () => {
    router.visit(window.history.back(), { preserveScroll: true })
}

const reload = () => {
    window.location.reload()
}

const goHome = () => {
    router.visit("/dashboard")
}
</script>

<template>
    <div class="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
        <div class="max-w-2xl w-full text-center">
            <h1 class="text-6xl font-bold text-gray-900 mb-4">Oops!</h1>
            <h2 class="text-3xl font-semibold text-gray-700 mb-4">{{ title }}</h2>
            <p class="text-gray-500 mb-8 max-w-md mx-auto">
                {{ description }}
            </p>

            <div class="inline-block px-4 py-2 bg-gray-100 rounded-lg mb-8">
                <code class="text-gray-600 font-mono">Error {{ status }}</code>
            </div>

            <div class="flex flex-col sm:flex-row gap-4 justify-center items-center">
                <button
                    @click="goBack"
                    class="flex items-center gap-2 px-6 py-3 bg-white text-gray-700 rounded-lg border border-gray-300 hover:bg-gray-50 transition-colors duration-200">
                    <component
                        :is="icons.ArrowLeft"
                        class="w-5 h-5" />
                    Go Back
                </button>

                <button
                    @click="reload"
                    class="flex items-center gap-2 px-6 py-3 bg-white text-gray-700 rounded-lg border border-gray-300 hover:bg-gray-50 transition-colors duration-200">
                    <component
                        :is="icons.RefreshCcw"
                        class="w-5 h-5" />
                    Try Again
                </button>

                <button
                    @click="goHome"
                    class="flex items-center gap-2 px-6 py-3 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors duration-200">
                    <component
                        :is="icons.Home"
                        class="w-5 h-5" />
                    Homepage
                </button>
            </div>
        </div>

        <div class="mt-16 text-sm text-gray-500">
            Need help?
            <router-link
                to="/contact"
                class="text-blue-600 hover:underline"
                >Contact Support</router-link
            >
        </div>
    </div>
</template>
